import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 10vw;
`;

export const Block = styled.div`
  margin: 1.4rem 0;
  position: relative;
  z-index: 999;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
