import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Blockquote = styled.div`
  margin: 9rem 0;

  blockquote {
    margin: 0;
    padding: 0;
  }
`;

export const Quote = styled.div`
  font-size: 2rem;
  line-height: 2.8rem;
`;

export const Attribution = styled.div`
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
  line-height: 1.05rem;
  margin-top: 0.7rem;
  text-transform: uppercase;

  p {
    font-size: inherit;
    line-height: inherit;
  }
`;
