import React from 'react';
import GatsbyImage from 'gatsby-image';

import { Wrapper, InnerWrapper, ImageWrapper, Caption } from './style';

export default ({ images, caption }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        {images
          ? images.map((image) => {
              return (
                <ImageWrapper aspectRatio={image.fluid.aspectRatio}>
                  <GatsbyImage
                    fluid={image.fluid}
                    key={image.id}
                    style={{
                      bottom: 0,
                      left: 0,
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      zIndex: 999,
                    }}
                  />
                </ImageWrapper>
              );
            })
          : null}
      </InnerWrapper>
      {caption ? (
        <Caption>
          <p>{caption}</p>
        </Caption>
      ) : null}
    </Wrapper>
  );
};
