import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 7.7rem 0;
`;

export const InnerWrapper = styled.div`
  align-items: baseline;
  display: flex;
  margin: 0 calc(-15vw + 0.7rem) 0 calc(-30vw + 0.7rem);

  @media screen and (max-width: 900px) {
    margin: 0 -0.7rem;
  }

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

export const ImageWrapper = styled.div`
  margin: 0.7rem;
  position: relative;
  width: 100%;

  &:after {
    content: '';
    display: block;
    padding-bottom: ${(props) => 100 / props.aspectRatio}%;
    width: 100%;
  }
`;

export const Caption = styled.figcaption`
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
  line-height: 1.05rem;
  margin-top: 0.7rem;
  text-transform: uppercase;

  p {
    font-size: inherit;
    line-height: inherit;
  }
`;
