import styled from 'styled-components';

export const Wrapper = styled.figure`
  margin: 8.4rem 0 8.4rem 0;
  padding: 0;
`;

export const InnerWrapper = styled.div`
  margin: 0 calc(-15vw + 1.4rem) 0 calc(-30vw + 1.4rem);
  position: relative;
  z-index: 999;

  @media screen and (max-width: 900px) {
    margin: 0;
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 56.25%;
    width: 100%;
  }
`;

export const Iframe = styled.iframe`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export const Caption = styled.figcaption`
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
  line-height: 1.05rem;
  margin-top: 0.7rem;
  text-transform: uppercase;

  p {
    font-size: inherit;
    line-height: inherit;
  }
`;
