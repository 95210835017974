import React from 'react';
import get from 'lodash/get';

import { Wrapper, Blockquote, Quote, Attribution } from './style';

export default ({ text, attribution }) => {
  return (
    <Wrapper>
      <Blockquote>
        <Quote
          dangerouslySetInnerHTML={{
            __html: get(text, 'childMarkdownRemark.html'),
          }}
        />
        <Attribution
          dangerouslySetInnerHTML={{
            __html: attribution,
          }}
        />
      </Blockquote>
    </Wrapper>
  );
};
