import React from 'react';

import { Wrapper, InnerWrapper, Iframe, Caption } from './style';

export default ({ caption, vimeoId }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <Iframe
          src={`https://player.vimeo.com/video/${vimeoId}`}
          frameBorder="none"
          allowFullscreen
        />
      </InnerWrapper>
      {caption ? (
        <Caption>
          <p>{caption}</p>
        </Caption>
      ) : null}
    </Wrapper>
  );
};
