import React from 'react';
import get from 'lodash/get';

import { Wrapper } from './style';

export default ({ text }) => {
  return (
    <Wrapper
      dangerouslySetInnerHTML={{
        __html: get(text, 'childMarkdownRemark.html'),
      }}
    />
  );
};
