import React from 'react';
import get from 'lodash/get';

import ContentBlockCredits from '../ContentBlockCredits';
import ContentBlockText from '../ContentBlockText';
import ContentBlockQuote from '../ContentBlockQuote';
import ContentBlockImages from '../ContentBlockImages';
import ContentBlockVideo from '../ContentBlockVideo';

import { Wrapper, Block } from './style';

const components = {
  ContentfulContentBlockCredits: ContentBlockCredits,
  ContentfulContentBlockText: ContentBlockText,
  ContentfulContentBlockQuote: ContentBlockQuote,
  ContentfulContentBlockImages: ContentBlockImages,
  ContentfulContentBlockVideo: ContentBlockVideo,
};

const ContentBlocks = ({ blocks }) => {
  return (
    <Wrapper>
      {blocks.map((block) => {
        const component = components[get(block, 'internal.type')];
        return component ? (
          <Block key={block.id}>{React.createElement(component, block)}</Block>
        ) : null;
      })}
    </Wrapper>
  );
};

export default ContentBlocks;
