import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import get from 'lodash/get';

import { Wrapper, Intro } from './style';

import Header from '../../components/Header';
import ContentBlocks from '../../components/ContentBlocks';

const WorkTemplate = ({ location, pageContext, data }) => {
  const project = get(data, 'contentfulWork');
  const metaImage = get(project, 'thumbnail.fluid.src');
  const metaDescription = `${project.title}, ${project.year}${
    project.ongoing ? ' (ongoing)' : ''
  }, ${project.materials}`;

  return (
    <Wrapper>
      <Helmet>
        <title>{`${project.title} | Sam Williams`}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={project.title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
      </Helmet>
      <Header intro={``} withBackButton />
      <Intro>
        <h1 style={{ margin: 0 }}>
          <em>{project.title}</em> ({project.year}
          {project.ongoing ? ', ongoing' : ''})
        </h1>
        <p style={{ margin: 0 }}>{project.materials}</p>
      </Intro>
      <ContentBlocks blocks={project.content} />
    </Wrapper>
  );
};

export default WorkTemplate;

export const workQuery = graphql`
  query WorkById($id: String!) {
    contentfulWork(id: { eq: $id }) {
      title
      year
      ongoing
      materials
      thumbnail {
        fluid(maxWidth: 2000) {
          src
        }
      }
      content {
        ... on ContentfulContentBlockCredits {
          id
          internal {
            type
          }
          text {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulContentBlockText {
          id
          internal {
            type
          }
          text {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulContentBlockQuote {
          id
          internal {
            type
          }
          text {
            childMarkdownRemark {
              html
            }
          }
          attribution
        }
        ... on ContentfulContentBlockImages {
          id
          internal {
            type
          }
          images {
            id
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          caption
        }
        ... on ContentfulContentBlockVideo {
          id
          internal {
            type
          }
          vimeoId
          caption
        }
      }
    }
  }
`;
